<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b>
            【学前儿童微运动手功能】
            <br/><br/>个人报告
          </b>
        </h1>
        <div class="author">
          <p>
            <i>

              <br/>
            </i>
          </p>
          <p>杨敦雄 编制</p>
        </div>
        <p class="title3 tx-l">测验介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p
                class="tips tx-idt2"
              >
                本测验主要作为评估零岁至七岁儿童运动-体适能的临床测评工具。目的是通过测验，确定儿童发展水平，以便进行早期干预和治疗，并根据测验结果分析，进一步了解儿童的干预计划。</p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">个案编号：{{baseInfo.sn}}</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">儿童姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_name"/>
              </div>
            </td>
            
            <th>儿童性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_sex"/>
              </div>
            </td>

           
          </tr>
        
          <tr>
           <th>出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.birthday"/>
              </div>
            </td> 
            <th width="140px">评估日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.create_time"/>
              </div>
            </td> 
           
            
          </tr>
           
          <tr>
         
            <th>换算年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.age"/>
              </div>
            </td>
          
            <th>主要照顾者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.care_man"/>
              </div>
            </td>
          </tr>
         
         
          <tr>
            <th>受访者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.parent"/>
              </div>
            </td>
            <th>与儿童关系</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.relation"/>
              </div>
            </td>
          </tr>
          <tr>
             <th>受访者电话</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.mobile"/>
              </div>
            </td>
            <th>居住地区</th>
            <td>
              <div class="input">
                <input type="text" readonly :value="baseInfo.province + baseInfo.city + baseInfo.district"/>
              </div>
            </td>
           
          </tr>
        </table>
        
        <p class="title3 tx-l">向度说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
           <th width="180px">基础粗大运动发展</th>
            
           <td class="tx-c" >评估不同阶段儿童大运动过程中，头颈稳定度和躯干稳定度，同时评量儿童在卧位转体、爬行、站立、行走的发展水平。</td>
           
         </tr>
         <tr>
           <th>动作姿势控制</th>
            
           <td class="tx-c">评估不同阶段儿童上下楼梯、跑步、跳跃等。</td>
           
         </tr>
         <tr>
           <th>身体平衡</th>
           
               <td class="tx-c">评估不同阶段儿童在单脚站立、托盘操作、沿线走等。</td>
           
         </tr>
         <tr>
           <th >基础体育活动</th>
            
           <td class="tx-c">评估不同阶段儿童球类活动、基础体适能、脚踏车骑行、社区设施使用、基础体操以及律动发展。</td>
          
         </tr>
         <tr>
           <th>生活自理</th>
            
           <td class="tx-c">评估不同阶段儿童在生活中大肌肉的使用状况。</td>
           
         </tr>
         
       </table>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 分数结果 -->
        <p class="title3 tx-l">发展规划</p>
        <table border="1" cellspacing="1" cellpadding="1">
          <!-- <tr>
            <th width="100px">等级</th>
            <td class="tx-c">等级1</td>
            <td class="tx-c">等级2</td>
            <td class="tx-c">等级3</td>
            <td class="tx-c">等级4</td>
            <td class="tx-c">等级5</td>
            <td class="tx-c">等级6</td>
            <td class="tx-c">等级7</td>
            <td class="tx-c">等级8</td>
            <td class="tx-c">等级9</td>
            <td class="tx-c">等级10</td>
            <td class="tx-c">等级11</td>
            <td class="tx-c">等级12</td>
            <td class="tx-c">等级13</td>
            <td class="tx-c">等级14</td>
          </tr> -->
          <!-- <tr>
            <th>得分小计</th>
            <td v-for="(list, index) in scoreList" :key="index" class="tx-c">{{list.level_score}}</td>
          </tr>
          <tr>
            <th>总得分</th>
            <td colspan="14" class="tx-c">{{scoreRe.score}}</td>
          </tr> -->
          <!-- <tr>
            <th>通过标准</th>
            <td v-if="baseInfo.month_age>=0&&baseInfo.month_age<=4" colspan="14" class="tx-c">2分或以上</td>
            <td v-if="baseInfo.month_age>=5&&baseInfo.month_age<=8" colspan="14" class="tx-c">7分或以上</td>
            <td v-if="baseInfo.month_age>=9&&baseInfo.month_age<=12" colspan="14" class="tx-c">10分或以上</td>
            <td
              v-if="baseInfo.month_age>=13&&baseInfo.month_age<=16"
              colspan="14"
              class="tx-c"
            >16分或以上
            </td>
            <td
              v-if="baseInfo.month_age>=17&&baseInfo.month_age<=20"
              colspan="14"
              class="tx-c"
            >20分或以上
            </td>
            <td
              v-if="baseInfo.month_age>=21&&baseInfo.month_age<=24"
              colspan="14"
              class="tx-c"
            >24分或以上
            </td>
            <td
              v-if="baseInfo.month_age>=25&&baseInfo.month_age<=28"
              colspan="14"
              class="tx-c"
            >33分或以上
            </td>
            <td
              v-if="baseInfo.month_age>=29&&baseInfo.month_age<=32"
              colspan="14"
              class="tx-c"
            >40分或以上
            </td>
            <td
              v-if="baseInfo.month_age>=33&&baseInfo.month_age<=36"
              colspan="14"
              class="tx-c"
            >44分或以上
            </td>
          </tr> -->
          <tr>
            <th width="50px">发展水平</th>
            <td colspan="14" class="tx-c">{{scoreRe.conclusion}}</td>
          </tr>
          <tr>
            <th>成长规划</th>
            <td colspan="14" class="tx-c">
              <p class="tips" v-html="scoreRe.desc"></p>
            </td>
          </tr>
        </table>
        <!-- <div v-show="scoreRe.conclusion!='正常'">
          <p class="title3 tx-l">疑似迟缓儿童通过题数剖面图</p>
          <p class="tips">儿童为疑似迟缓，可藉由此剖面图，了解儿童在各月龄组所通过的题数，可看出儿童目前的沟通及语言能力落在那个月龄组内。</p>
          <div class="charts" id="charts1"></div>
        </div> -->
      </div>
      <!-- <div class="table" :class="{'pdfheight': isPrint}">
         分数结果
        <p class="title3 tx-l">零岁至三岁婴幼儿词汇量总计</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="190px">词汇总类(量)</th>
            <th>听懂</th>
            <th>说出</th>
          </tr>
          <tr>
            <th>一般名词(68)</th>
            <td class="tx-c">{{word.type_score.normal.know_score}}</td>
            <td class="tx-c">{{word.type_score.normal.speak_score}}</td>
          </tr>
          <tr>
            <th>衣物名称(9)</th>
            <td class="tx-c">{{word.type_score.cloth.know_score}}</td>
            <td class="tx-c">{{word.type_score.cloth.speak_score}}</td>
          </tr>
          <tr>
            <th>方向词(9)</th>
            <td class="tx-c">{{word.type_score.position.know_score}}</td>
            <td class="tx-c">{{word.type_score.position.speak_score}}</td>
          </tr>
          <tr>
            <th>代词(8)</th>
            <td class="tx-c">{{word.type_score.pron.know_score}}</td>
            <td class="tx-c">{{word.type_score.pron.speak_score}}</td>
          </tr>
          <tr>
            <th>身体部位名称(14)</th>
            <td class="tx-c">{{word.type_score.body.know_score}}</td>
            <td class="tx-c">{{word.type_score.body.speak_score}}</td>
          </tr>
          <tr></tr>
          <tr>
            <th>食物名称(30)</th>
            <td class="tx-c">{{word.type_score.food.know_score}}</td>
            <td class="tx-c">{{word.type_score.food.speak_score}}</td>
          </tr>
          <tr>
            <th>动物名称(21)</th>
            <td class="tx-c">{{word.type_score.animal.know_score}}</td>
            <td class="tx-c">{{word.type_score.animal.speak_score}}</td>
          </tr>
          <tr>
            <th>形容词(24)</th>
            <td class="tx-c">{{word.type_score.adj.know_score}}</td>
            <td class="tx-c">{{word.type_score.adj.speak_score}}</td>
          </tr>
          <tr>
            <th>动作词(73)</th>
            <td class="tx-c">{{word.type_score.verb.know_score}}</td>
            <td class="tx-c">{{word.type_score.verb.speak_score}}</td>
          </tr>
          <tr>
            <th>称谓/名字(19))</th>
            <td class="tx-c">{{word.type_score.person.know_score}}</td>
            <td class="tx-c">{{word.type_score.person.speak_score}}</td>
          </tr>
          <tr>
            <th>社交语(8)</th>
            <td class="tx-c">{{word.type_score.social.know_score}}</td>
            <td class="tx-c">{{word.type_score.social.speak_score}}</td>
          </tr>
          <tr>
            <th>其他词汇(28)</th>
            <td class="tx-c">{{word.type_score.other.know_score}}</td>
            <td class="tx-c">{{word.type_score.other.speak_score}}</td>
          </tr>
          <tr>
            <th>理解性词汇量总计</th>
            <td class="tx-l" colspan="2">
              <div style="margin-left:10px;">数量：{{word.total_score.know_score}}</div>
              <p class="tips other-word" style="margin-left: 10px;">
                <span v-for="(item, index) in knowWords" :key="index">
                  {{item.word}}
                  <i>、</i>
                </span>
                <span v-if="knowWords.length == 0">无</span>
              </p>
            </td>
          </tr>
          <tr>
            <th>表达性词汇量总计</th>
            <td class="tx-l" colspan="2">
              <div style="margin-left:10px;">数量：{{word.total_score.speak_score}}</div>
              <p class="tips other-word" style="margin-left: 10px;">
                <span v-for="(item, index) in speakWords" :key="index">
                  {{item.word}}
                  <i>、</i>
                </span>
                <span v-if="speakWords.length == 0">无</span>
              </p>
            </td>
          </tr>
          <tr>
            <th>其他能听懂的词汇</th>
            <td colspan="2">
              <div style="margin-left:10px;">数量：{{word.new.speak.length}}</div>
              <p class="tips other-word" style="margin-left:10px;">
                <span v-for="(item, index) in word.new.speak" :key="index">
                  {{item.word}}
                  <i>、</i>
                </span>
                <span v-if="word.new.speak.length == 0">无</span>
              </p>
            </td>
          </tr>
          <tr>
            <th>其他能说出的词汇</th>
            <td colspan="2">
              <div style="margin-left:10px;">数量：{{word.new.know.length}}</div>
              <p class="tips other-word" style="margin-left:10px;">
                <span v-for="(item, index) in word.new.know" :key="index">
                  {{item.word}}
                  <i>、</i>
                </span>
                <span v-if="word.new.know.length == 0">无</span>
              </p>
            </td>
          </tr>
        </table>
        <div class="tips">注：词汇量调查表仅供家长掌握孩子目前词汇数量，若需进阶信息请申请进阶评估报告。</div>
      </div> -->
      <div class="table" :class="{'pdfheight': isPrint}">
      <!--
        <p class="title3 tx-l">备注</p>
        <div class="tips">
          <p>如果您对本测验结果或解释有任何疑问，或者有想进一步了解的地方，请与施测人员讨论与咨询。</p>
        </div>

        <p class="title3 tx-l">补充说明</p>
        <div class="tips">
          <p>{{baseInfo.remark}}</p>
        </div>
        -->
      </div>

    </div>

    <!-- <button class="print" v-on:click="printPdf">打印</button> -->
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from "@/components/HeaderNav";
  import FooterNav from "@/components/FooterNav";
  import echarts from "echarts";

  export default {
    name: "aboutus",
    data() {
      return {
        current: "",
        isPrint: false,
        baseInfo: {},
        cateScore: [],
        holding: "",
        result: "",
        scoreList: [],
        scoreRe: {},
        word: {},
        know: [],
        speak: [],
        vocabulary: [
          "一般名词(68)",
          "衣物名称(9)",
          "方位词(9)",
          "代词(8)",
          "身体部位名称(14)",
          "食物名称(30)",
          "动物名称(21)",
          "形容词(24)",
          "动作词(73)",
          "称谓／名字(19)",
          "社交语(8)",
          "其他词汇(28)"
        ],
        knowWords: [],
        speakWords: []
      };
    },
    components: {
      HeaderNav,
      FooterNav,
      echarts
    },
    methods: {
      getData() {
        let params = {
          exam_id: this.$route.query.eid
        };
        this.$post("report/getReport", params)
          .then(res => {
            console.log(res);
            if (res.code === 1) {
              this.baseInfo = res.data.base_info;
              this.detail = res.data.base_info.detail;
              this.cateScore = res.data.cate_score;
              this.holding = res.data.holding;
              this.result = res.data.result;
              this.scoreList = res.data.score.level_score;
              this.scoreRe = res.data.cate_score[0];
              //插入换行符
              this.scoreRe.desc = this.scoreRe.desc.replace(/\n/g, "<br/>");
              this.word = res.data.word;
              this.knowWords = res.data.word.total_score.know;
              this.speakWords = res.data.word.total_score.speak;
              for (let i in this.word.type_score) {
                this.know.push(this.word.type_score[i].know_score);
                this.speak.push(this.word.type_score[i].speak_score);
              }
              this.$nextTick(() => {
                this.drawLine();
                this.drawLine2();
              });
              this.$nextTick(() => {
                if (this.$route.query.print) {
                  this.printPdf();
                }
              });
            } else {
              this.$layer.msg(res.msg);
            }
          })
          .catch(response => {
            this.$layer.msg("LOADING...");
          });
      },
      printPdf() {
        this.isPrint = true;
        setTimeout(() => {
          this.getPdf("学前儿童运动-体适能测评", () => {
            this.isPrint = false;
          });
        }, 1500)
      },
      drawLine() {
        let data = [];
        for (let i = 0; i < this.scoreList.length; i++) {
          data.push(this.scoreList[i].level_score);
        }
        // console.log("data", data);
        // // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("charts1"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "",
            textAlign: "left",
            x: "center",
            y: "top"
          },
          tooltip: {},
          xAxis: [
            {
              name: "发展水平",
              data: [
                "等级1",
                "等级2",
                "等级3",
                "等级4",
                "等级5",
                "等级6",
                "等级7",
                "等级8",
                "等级9",
                "等级10",
                "等级11",
                "等级12",
                "等级13",
                "等级14"
              ]
            }
          ],
          yAxis: {
            type: "value",
            scale: true,
            name: "题数",
            max: 6,
            min: 0
          },
          series: [
            {
              type: "bar",
              data: data,
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "black",
                      fontSize: 16
                    }
                  }
                }
              }
            },
            {
              data: [5, 5, 5, 5, 6, 6, 6, 6, 6],
              type: "line",
              step: "middle",
              symbol: "none"
            }
          ]
        });
      },
      drawLine2() {
        let know = [];
        let speak = [];
        for (let i in this.word.type_score) {
          know.push(this.word.type_score[i].know_score);
          speak.push(this.word.type_score[i].speak_score);
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("charts2"));
        // 绘制图表
        let seriesLabel = {
          normal: {
            show: true,
            color: "black",
            position: "right" //在上方显示
          }
        };
        myChart.setOption({
          title: {
            text: "零岁至三岁婴幼儿词汇量总计",
            textAlign: "left",
            x: "center",
            y: "top"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          legend: {
            data: ["听懂", "说出"],
            orient: "horizontal",
            x: "right",
            y: "top"
          },
          grid: {
            left: "3%",
            right: "8%",
            bottom: "6%",
            containLabel: true
          },
          xAxis: {
            name: "数量",
            type: "value",
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            name: "词汇种类",
            type: "category",
            data: [
              "一般名词(68)",
              "衣物名称(9)",
              "方位词(9)",
              "代词(8)",
              "身体部位名称(14)",
              "食物名称(30)",
              "动物名称(21)",
              "形容词(24)",
              "动作词(73)",
              "称谓／名字(19)",
              "社交语(8)",
              "其他词汇(28)"
            ]
          },
          series: [
            {
              name: "听懂",
              type: "bar",
              label: seriesLabel,
              data: know
            },
            {
              name: "说出",
              type: "bar",
              label: seriesLabel,
              data: speak
            }
          ]
        });
      }
    },
    created() {
      this.getData();
    }
  };
</script>

<style lang="less" scoped>
  @import url("../../assets/css/page/result");
</style>
